<template>
  <div class="footer">
    <div class="wrap">
      <div class="contacts">
        <p>м. Бердичів, пл. Центральна 1</p>
        <a href="tel:+380414327166">+380 (4143) 2 71 66</a>
      </div>
      <div class="label">
        <img src="@/assets/logo_brd.png" alt="" />
        <h5>Бердичів</h5>
        <p>Документи</p>
      </div>
      <div class="experimental">
        <span class="beta">BETA</span><span>Сервіс працює в режимі дослідної експлуатації.</span>
        <p>У випадку виникнення зауважень <a href="mailto:smart.berdychiv@gmail.com">сповістіть нас</a>.</p>
      </div>
    </div>
    <p class="disclaimer">
      © 2020 Весь контент доступний за ліцензією Creative Commons Attribution 4.0 International license, якщо не
      зазначено інше
    </p>
  </div>
</template>

<style lang="scss" scoped>
  .footer {
    position: relative;
    margin-top: 32px;
    padding: 32px 2vw 12px;

    p {
      font-size: 0.8em;
    }

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      left: 0;
      top: 0;
      background: rgb(194, 194, 194);
    }

    .wrap {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      place-items: center;
      grid-gap: 10px;
    }

    .contacts,
    .social {
      font-size: 0.8em;
      align-self: end;
    }

    .label {
      display: grid;
      place-items: center;
    }

    .experimental {
      align-self: end;

      a {
        text-decoration: underline;
      }

      span,
      p {
        font-size: 0.6em;

        &.beta {
          color: #fff;
          background: $acs;
          border-radius: 999px;
          padding: 3px 5px;
          margin: 0 3px 0 -45px;
        }
      }
    }

    h5,
    p,
    a {
      margin: 0;
      color: rgb(120, 120, 120);

      &.disclaimer {
        grid-column: 1 / span 3;
        font-size: 0.6em;
        margin-top: 10px;
        text-align: center;
      }
    }

    a:hover {
      color: $acs;
    }
  }
</style>
