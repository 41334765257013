<template>
  <div class="home">
    <Hero />
    <Menu />
  </div>
</template>

<script>
  import Hero from "../components/home/Hero.vue";
  import Menu from "../components/home/Menu.vue";

  export default {
    components: { Hero, Menu },
    name: "Home",
  };
</script>
