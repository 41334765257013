<template>
  <div class="header">
    <div class="header-bar">
      <router-link to="/" class="logo">
        <p>Твоє житло</p>
      </router-link>

      <div class="header-contacts">
        <a href="tel:+380414327166">+380 (4143) 2 71 66</a>
        <a href="mailto:vukonkom@gmail.com">vukonkom@gmail.com</a>
      </div>
    </div>
  </div>
</template>
<script>
  export default {};
</script>
<style lang="scss" scoped>
  .header {
    position: relative;
    width: 100%;
    height: 100px;
    z-index: 9;

    @media only screen and (max-width: 720px) {
      height: 60px;
    }

    .header-bar {
      display: grid;
      grid-template-columns: 180px 1fr;
      align-items: center;
      grid-gap: 1em;
      width: 100%;
      height: 100%;
      padding: 0 1em;

      .logo {
        position: relative;
        display: flex;
        align-items: center;

        &:hover {
          color: $black;
        }

        p {
          position: relative;
          display: block;
          margin: 0;
          font-size: 1.2rem;
          font-weight: 600;

          &::after {
            position: absolute;
            content: "";
            width: 8px;
            height: 8px;
            bottom: 12px;
            right: -12px;
            background: $acs;
            border-radius: 50%;
            border: 0.5px solid #fff;
          }
        }
      }

      .header-contacts {
        display: flex;
        flex-wrap: wrap;
        justify-self: end;
        font-size: 0.8em;

        a {
          margin: 0 0.5em;
          white-space: nowrap;
        }
      }
    }
  }
</style>
