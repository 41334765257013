export default {
  names: [
    "Список громадян, які перебувають на обліку на загальних підставах",
    "Список громадян, які перебувають на обліку для першочергового отримання житла",
    "Список громадян, які користуються правом позачергового забезпечення житлом",
    "Список сімей інвалідів ВВв і прирівняних до них осіб",
    "Зведена база даних квартирного обліку учасників бойових дій в Афганістані та воєнних конфліктів у зарубіжних країнах",
    "Список членів сімей загиблих військовослужбовців",
    "Зведена база даних квартирного обліку безквартирних військовослужбовців, звільнених в запас",
    "Список громадян віднесених до І категорії постраждалих внаслідок аварії на ЧАЕС",
    "Список переселенців із зони гарантованого добровільного відселення та посиленого радіоекологічного контролю",
    "Список учасників ліквідації наслідків Чорнобильської катастрофи І категорії, які перебувають на черзі для поліпшення житлових умов",
    "Список учасників ліквідації наслідків Чорнобильської катастрофи ІІ категорії, які перебувають на черзі для поліпшення житлових умов",
    "Список сімей які потребують забезпечення житловою площею в зв’язку з відселенням з аварійних будинків",
    "Список учасників бойових дій якими подано заяви на отримання земельних ділянок для будівництва та обслуговування жилих будинків, господарських будівель і споруд",
    "Список учасників бойових дій які отримали дозвіл на виготовлення проекту відведення земельних ділянок для будівництва та обслуговування жилих будинків, господарських будівель і споруд",
    "Список учасників бойових дій яким передано у власність земельні ділянки для будівництва та обслуговування жилих будинків, господарських будівель і споруд",
  ],
};
